.addCommentModal {
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 9999;
    width: 100%;
    height: 100%;
    padding-top: 100px;
    background-color: black;
    background-color: rgba(0, 0, 0, 0.4);
    -webkit-transition: 2s;
    overflow: auto;
    transition: all 1s linear;
}

.addCommentModal .mainContent {
    background-color: #fefefe;
    margin: auto;
    padding: 30px;
    border-radius: 8px;
    width: 350px;
    max-width: 80%;
    transition: all 1s linear;
}

.addCommentModal .apiError {
    color: red;
    font-style: italic;
}


.addCommentModal .cancelButton {
    margin-left: 85%;
    font-size: 20px;
    right: 32px;
    top: 32px;
    width: 32px;
    height: 32px;
    opacity: 0.3;
    cursor: pointer;
}

.addCommentModal .cancelButton:hover {
    opacity: 1;
}

.addCommentModal .cancelButton:before, .cancelButton:after {
    left: 15px;
    content: ' ';
    height: 33px;
    width: 2px;
    background-color: #333;
}

.addCommentModal .cancelButton:before {
    transform: rotate(45deg);
}

.addCommentModal .cancelButton:after {
    transform: rotate(-45deg);
}

.addCommentModal .formHeader {
    margin-top: -38px;
    margin-bottom: 15px;
    font-size: 30px;
    margin-left: 30px;
    margin-right: 30px;
}

.addCommentModal .form-group {
    margin-bottom: 15px;
    text-align: left;
}

.addCommentModal .button {
    margin-top: 10px;
}

.addCommentModal .buttonGroup {
    text-align: center;
}

.addCommentModal label {
    margin-bottom: 6px;
}

.addCommentModal .action {
    margin-right: -15px
}