.post {
    text-align: left;
    margin: 8px 0;
    padding: 30px;
    border-radius: 4px;
    background-color: white;
}

.postContainer:first-child .post {
    margin-top: 25px;
}

.postContainer:last-child .post {
    margin-bottom: 50vh;
}

.post p.postedBy {
    font-style: italic;
    color: #808080;
    font-size: 14px;
    margin-bottom: 10px;
}

.post h2 {
    margin-top: 5px;
    margin-bottom: 10px;
}