.white {
    background-color: white;
}

.mainHeader {
    width: 100%;
    display: flex;
    background-color: #E1D8CF;
    height: 25vh;
    margin-bottom: 8px;

    position: fixed;
    margin-left: 0;
    margin-right: 0;

    box-shadow: 0px 6px 10px rgb(0 0 0 / 18%);
}

.mainHeader .pageTitle {
    font-size: 75px;
    font-weight: 500;
    line-height: 25vh;
    color: inherit;
}

.mainHeader .button {
    position: absolute;
    top: 50%;
    left: 80%;
    transform: translate(-50%, -50%);
}

.mainContent {
    padding-top: 25vh;
}

@media (max-width: 1024px) {
    .mainHeader {
        height: 25vh;
    }

    .mainHeader .pageTitle {
        line-height: 15vh;
    }

    .mainHeader .button {
        top: 75%;
        left: 50%;
    }

    .mainContent {
        padding-top: 25vh;
    }
}

.vertical-center {
    position: relative;
    display: flex;
    height: 100%;
}

.button {
    width: 150px;
    height: 50px;
    line-height: 50px;
    font-weight: 700;
    font-size: 18px;
    background-color: #262626;
    color: white;
    cursor: pointer;
    transition: .3s;
    border: none;
}

.button:hover {
    background-color: #736e6e;
}

.pageTitle {
    text-decoration: none;
}

.post.noPosts {
    margin-top: 50px;
    height: 25vh;
    padding: 0 20px;
}

.post.noPosts p {
    margin-bottom: 0;
    line-height: 25vh;
}

.post.endOfPosts {
    margin-top: 8px;
    margin-bottom: 25vh;
    height: 50px;
    padding: 0;
}

.post.endOfPosts p {
    margin-bottom: 0;
    line-height: 50px;
    text-align: center;
    margin: 0;
}