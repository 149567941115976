.commentContainer {
    margin: 15px 0 15px 20px;
    padding-left: 20px;
    border-left-style: solid;
    border-left-width: 2px;
    border-left-color: gray;
}

.comments .noComments {
    margin: 30px 0;
    padding-left: 20px;
}

.commentContainer .username {
    font-style: italic;
}

.showComments, .hideComments, .addComment {
    text-decoration: underline;
    color: #525252;
    cursor: pointer;
    display: inline-block;
}